import { connect } from 'react-redux';
import MediaFilterDropdown from '../components/media/MediaFilterDropdown';
import setMediaFilter from '../state/actionCreators/setMediaFilter';

const mapStateToProps = (state) => {
  const { mediaFilter } = state.media;
  return {
    selectedMediaFilter: mediaFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  setMediaFilter: value => dispatch(setMediaFilter(value)),
});

const MediaFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaFilterDropdown);

export default MediaFilterContainer;
