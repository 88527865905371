import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Container from '../components/core/Container';
import PageTitle from '../elements/PageTitle';
import PageHeader from '../elements/PageHeader';
import PageBody from '../elements/PageBody';
import BackLink from '../components/core/BackLink';
import MediaFilterContainer from '../containers/MediaFilterContainer';
import MediaResultsContainer from '../containers/MediaResultsContainer';
import Layout from '../components/core/Layout';

const Media = ({ data }) => {
  const {
    allMediaJson: { media },
  } = data;

  const missingImageSizes = data.allFile.edges[0].node.childImageSharp.sizes;

  return (
    <Layout>
      <Container>
        <PageHeader>
          <div>
            <PageTitle>Explore media</PageTitle>
            <BackLink text="Back" />
          </div>
          <MediaFilterContainer />
        </PageHeader>
        <PageBody>
          <MediaResultsContainer media={media} missingImageSizes={missingImageSizes} />
        </PageBody>
      </Container>
    </Layout>
  );
};

Media.propTypes = {
  data: PropTypes.shape({
    allMediaJson: PropTypes.object.isRequired,
  }).isRequired,
};

export default Media;

export const MediaQuery = graphql`
  query MediaQuery {
    allFile(filter: { name: { eq: "missing-image" } }) {
      edges {
        node {
          ...CardImageSizesFragment
        }
      }
    }
    allMediaJson {
      ...MediaSummaryFragment
    }
  }
`;
