import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../elements/Select';
import styles from './styles.module.css';

const MediaFilterDropdown = ({ setMediaFilter, selectedMediaFilter }) => {
  const onSelectChange = e => setMediaFilter(e.target.value);

  return (
    <Select className={styles.select} onChange={onSelectChange} value={selectedMediaFilter}>
      <option value="">All video and audio</option>
      <option value="video">Video</option>
      <option value="audio">Audio</option>
    </Select>
  );
};

MediaFilterDropdown.propTypes = {
  selectedMediaFilter: PropTypes.string.isRequired,
  setMediaFilter: PropTypes.func.isRequired,
};

export default MediaFilterDropdown;
