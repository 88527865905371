import { connect } from 'react-redux';
import MediaResults from '../components/media/MediaResults';

const mapStateToProps = (state) => {
  const { mediaFilter } = state.media;
  return {
    selectedMediaFilter: mediaFilter,
  };
};

const MediaResultsContainer = connect(mapStateToProps)(MediaResults);

export default MediaResultsContainer;
