/* eslint react/forbid-prop-types: 0 */
/* eslint react/no-array-index-key: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import CardRow from '../../core/CardRow';
import MediaCard from '../MediaCard';

const MediaResults = ({ media, missingImageSizes, selectedMediaFilter }) => {
  const filteredMedia = selectedMediaFilter
    ? media.filter(m => m.item.type === selectedMediaFilter)
    : media;

  return (
    <div>
      {chunk(filteredMedia, 3).map((c, i) => (
        <CardRow key={`chunk-${i}`}>
          {c.map(({ item }) => (
            <MediaCard key={item.media_id} media={item} missingImageSizes={missingImageSizes} />
          ))}
        </CardRow>
      ))}
    </div>
  );
};

MediaResults.propTypes = {
  media: PropTypes.array.isRequired,
  missingImageSizes: PropTypes.object.isRequired,
  selectedMediaFilter: PropTypes.string.isRequired,
};

export default MediaResults;
